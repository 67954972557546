import React from "react"
import Config from "../../../../utility/Config"
import { useVideoPlayer } from "../../../../components/VideoPlayer/VideoPlayerProvider"
import { deconstructPlaybackUrl } from "../../../../utility/Utilities"
import { useFieldVisionData } from "../../../../utility/useFieldVisionData"
// import { secToFrame } from "../../../../utility/useFieldVisionData"

function ExportGoal ({
    event=null,
    setStart, 
    setEnd, 
    seekTo,
    setSkipSegment,
    setSkipStart,
    setSkipEnd,
    originalStartEndPoint=null,
}) {
    
    const {getCurrentTime, playbackUrl} = useVideoPlayer()
    let [assetId, assetTimeOffsetMs] = deconstructPlaybackUrl(playbackUrl)
    const fps = Config.expectedFrameRate
    
    const audioMap = useFieldVisionData("audio/rms", 0, getCurrentTime, assetId, assetTimeOffsetMs, fps, true, "&track=-1")
    const transition = useFieldVisionData("transitions", 0, getCurrentTime, assetId, assetTimeOffsetMs, fps, true, "", 120000)

    const hasGoalWallClockTime = event?.wall_clock_time
    const wallClockTimestamp = new Date(event?.wall_clock_time).getTime()
    const recordingTimestamp = new Date(event?.playlist.recording_timestamp).getTime()
    const startToGoalDiff = (wallClockTimestamp - recordingTimestamp) / 1000

    // const assetStartTime = new Date("2024-12-07T16:31:39.000000Z").getTime()
    // console.log(recordingTimestamp, (recordingTimestamp - assetStartTime));
    // console.log((wallClockTimestamp - assetStartTime) - (recordingTimestamp - assetStartTime))
    // console.log(wallClockTimestamp - recordingTimestamp)
    
    // useEffect(() => {
    //     if (!skipSegment) return
    //     if (Math.abs(skipStart - currentTime) <= 0.1) {
    //         seekTo(skipEnd)
    //     }
    // }, [currentTime, skipSegment])

    const timeStamps = playbackUrl.split("/")[5].split(":")
    const clipStartTime = timeStamps[1]/1000
    const clipEndTime = timeStamps[2]/1000
    const clipStartFrame = clipStartTime * fps
    const clipEndFrame = clipEndTime * fps

    const filteredEntries = Array.from(audioMap.entries())
        .filter(([key]) => key >= clipStartFrame && key <= clipEndFrame);

    const maxEntry = filteredEntries.length > 0 
        ? filteredEntries.reduce((maxAudio, audio) => audio[1] > maxAudio[1] ? audio : maxAudio) 
        : []

    const loudestFrame = maxEntry[0] || 0
    const soundPeakGoalTime = (loudestFrame/fps) - clipStartTime

    const wallClockGoalTime = (originalStartEndPoint?.start + startToGoalDiff) - 2

    const goalTime = hasGoalWallClockTime ? wallClockGoalTime : soundPeakGoalTime
    const startBeforeGoal = goalTime - 7
    const afterGoal = goalTime

    // useEffect(() => {
    //     if (loudestTimeInPlayer) setPeakIndicator(loudestTimeInPlayer)
    // }, [loudestTimeInPlayer])

    const replayFrames = []
    for (const [key, value] of transition) {
        if (value.includes("logo_resnet_v2")) {
            if (key > clipStartFrame && key < clipEndFrame) {
                replayFrames.push(key)
            }
        }
    }
    replayFrames.sort((a, b) => a - b)

    if (replayFrames.length === 0) return null
    
    const firstReplayFrame = replayFrames[0]
    const lastReplayFrame = replayFrames[replayFrames.length - 1]
    const firstReplayTimeInPlayer = (firstReplayFrame/fps) - clipStartTime
    const lastReplayTimeInPlayer = firstReplayTimeInPlayer + ((lastReplayFrame - firstReplayFrame)/fps)

    const setExportGoalRange = () => {
        setSkipSegment(true)
        setStart(startBeforeGoal)
        setEnd(lastReplayTimeInPlayer)
        setSkipStart(afterGoal)
        setSkipEnd(firstReplayTimeInPlayer)
        seekTo(startBeforeGoal)
    }

    return (
        <button onClick={setExportGoalRange} className="goal-segment-button">
            SHL goal
        </button>
    )
}

export default ExportGoal